

.bg-gradient-info.bg-subHead {
  background: linear-gradient(87deg, #172b4d 0, #9a811f 100%) !important;

}


.bg-gradient-info.auth-bg {
  background: #9a811f  !important;
}


.bg-gradient-default.bg-changes-n {
  background: linear-gradient(87deg, #172b4d 0,  #9a811f 100%) !important;
}
.errorVal{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
  }

.custom-alert {
  position: fixed !important;
  top: 0;
  /* left: 0; */
  right: 0;
  margin: 15px;
  z-index: 1050; 
}

.custom-modal-header {
  background-color: #9a811f; /* Change this to your desired color */
  color: white; /* Ensure text is readable on the custom background */
}

.table-cell-wrap {
  word-wrap: break-word;
  white-space: normal;
  max-width: 200px; /* Adjust this value as needed */
  overflow: hidden;
  text-overflow: ellipsis;
}

.required:after {
  content: " *";
  color: #f5365c;
}

#inputds {
  display: none;
}

#labimg {
  width: 50%;
  display: block !important;
}

#labimg input {
  display: none;
}

.dt-btn {
  color: #f5365c;
}

.pointer {
  cursor: pointer;
}
.gallery {
  display: flex;
  /* overflow: scroll; */
  /* scrollbar-color: red orange; */
  /* scrollbar-width: thin; */
}

.gallery img {
  margin: 10px;
}

.droptextcs {
  margin: 20px;
  font-size: 15px;
}

.profile_inner_text ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.profile_inner_text ul li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.profile_inner_text ul li .first {
  display: block;
  width: 33%;
  font-weight: 600;
  color: #4B3B5A;
}

.profile_inner_text ul li .last {
  color: #080E2B;
  font-weight: 500;
}

.profile-error .invalid-feedback {
  display: block;
}

.select-filter-wrapper {
  padding: 10px 20px;
  margin-bottom: 10px;
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.form-upper{
  margin-top: -6rem;
  z-index: 0;
  position: relative;
}

.form-upper form {
  /* z-index: 7; */
  /* position: relative; */
  background-color: white;
  padding: 20px 1.5rem;
  border-radius: 10px;
}


/* 07-08-2024 */
.custom-control {
  padding-left: 2rem !important;
}

.custom-control-label::before, 
.custom-control-label::after {
  left: -2rem !important;
}


.avatar img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
}
#sidenav-main .navbar-nav .nav-link {
  padding: 3px 0;
}
/* 
#sidenav-main .navbar-nav .nav-link.active {
  padding-left: 20px;
} */
#sidenav-main .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0 !important;
  border-right: 2px solid #000 !important;
  border-bottom: 2px solid #000 !important;
  border-left: 0 !important;
  transform: rotate(-45deg);
  width: 8px;
  height: 8px;
  transition: 0.1s all;
}

/* #sidenav-main .dropdown-toggle.arrow-up::after{
  transform: rotate(45deg);
} */
#sidenav-main .nav-item:has(.collapse.show) .dropdown-toggle::after{
  transform: rotate(45deg);
  transition: 0.1s all;

}
#sidenav-main  .navbar-nav {
  row-gap: 20px;
}
#sidenav-main .nav-item .collapse.show {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px 0;
}

.sub-menu-item {
  padding-left:15px;
}

.sub-menu-link {
  padding-left: 10px;
}

.card-profile-image img {
  /* max-width: 180px; */
  border-radius: 0.375rem;
  transform: translate(-50%, -30%);
  position: absolute;
  left: 50%;
  transition: all 0.15s ease;
  object-fit: cover;
  height: 150px;
  object-fit: cover;
  width: 150px;
  object-position: center;
}
.clr-navy{
  color: #142637 !important;
}

.clr-brown{
  color: #685508 !important;
}

.clr-white{
  color: #fff;
}

.cursor{
  cursor: pointer;
}

.font_20{
  font-size: 20px;
}


.font_16{
  font-size: 16px;
}

.font_14{
  font-size: 14px;
}


.font_500{
  font-weight: 500;
}
.font_600{
  font-weight: 600;
}
.font_700{
  font-weight: 700;
}
.navbar-horizontal .navbar-brand img.logo {
  height: 50px;
}



/* A 08-08- 24  */

.btn-theme1{
  color: #fff !important;
  background-color: #142637 !important;
  padding: 8px 16px!important;
  font-size: 14px !important;
  min-width: 90px;
  height: 42px;
}

.btn-theme2{
  color:#ffffff !important;
  background-color: #685508 !important;
  padding: 8px 16px!important;
  font-size: 14px !important;
  min-width: 90px;
  height: 42px;
}


.login-wrapper{
  min-height: calc(100vh - 75px);
}

.card-title{
  font-size: 20px;
  font-weight: 700;
  color: #142637 !important;
  text-transform: capitalize;
}
.form-title1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.login-wrapper .input-group-alternative {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 2px 0 rgba(0, 0, 0, 1.02);
}

.login-wrapper form {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
#sidenav-main {
  padding: 25px 0;
  z-index: 99;
}
#sidenav-main .navbar-brand-img {
  width: 160px;
  margin: auto;
  height: auto;
}

#sidenav-main .navbar-collapse {
  margin: 25px 0;
  padding-top: 20px;
  border-top: 2px solid;
}
.custom-control-alternative .checkbox-label.custom-control-label::before {
  border-color: rgb(20 39 56 / 30%);
}
.custom-checkbox .custom-control-input:checked ~ .checkbox-label.custom-control-label::before{
  background-color: #142637;
}

.custom-checkbox .custom-control-input:checked ~ .checkbox-label.custom-control-label::after {
  background-image: url("/public/tick-arrow.png") !important;
}
 .btn-primary {
  color: white;
  background-color:#142637 !important;
  border-color: #142637 !important;
}
.form-control {
  color: #142637 !important; 
  font-weight: 600 !important;
}
.dropdown-item {
  color: #142637 !important;
  font-weight: 700 !important;
}
#sidenav-main .sidebar-nav-item .menu-link{
  font-size: 16px;
  font-weight: 700;
  color:#142637;
}
#sidenav-main .navbar-nav {
  margin-left: 0;
}
#sidenav-main .navbar-collapse:before {
  display: none;
}

#sidenav-main .navbar-nav .sub-menu-link {
  padding: 8px 0;
}

#sidenav-main .navbar-nav .nav-link.active:before{
  display: none;
}

#sidenav-main .navbar-nav .nav-link.active {
  color: #685508 !important;
  font-weight: 700;
}
.card .table td, .table th {
  padding: 1.5rem !important;
}

.form-control-label {
  color: #142637 !important;
  font-size: 16px !important;
}

.table .thead-light th {
  color: #142637 !important;
  font-size: 14px;
}
.profile-title {
  color: #fff !important;
  text-transform: capitalize;
}

.breadcrumb-wrapper {
  border-radius: 12px;
}
.breadcrumb-wrapper .breadcrumb{
  background-color: transparent;
  margin-bottom: 0px;
}

.breadcrumb-wrapper .breadcrumb-item.active{
  color: #ffffff;
  font-weight: 700;
} 

.breadcrumb-wrapper .breadcrumb-link{
  color: #ffffff;
}

.bg-subHead.pb-5{
  padding-bottom: 1rem !important;
}

.pagination .btn-secondary {
  background-color: #ffffff !important;
  border-color: #142637 !important;
  color: #142637 !important;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination .btn-secondary.active{
  background-color: #142637 !important;
  border-color: #142637 !important;
  color:#ffffff !important;
}

.btn-light {
  background-color: #685508 !important ;
}

.table-cell-wrap .btn {
  min-width: 80px;
  height: 40px;
}
.table td{
  color: #142637 !important;
  font-size: 14px !important;
}
.copyright.text-muted {
  color: #142637 !important;
}

.card-stats {
  height: 100%;
}
.header.pt-lg-8, .py-lg-8 {
  padding-top: 5rem !important;
}

.header .display-2 {
  font-size: 3rem;
  font-weight: 600;
  /* line-height: 55px; */
}


.card_border{
  border-radius: 6px !important;
}

.copyright.outer-page a{
  color: #ffffff !important;
  text-decoration: underline;
}

select{
  cursor: pointer;
}

.select-input {
  height: 43px;
  padding: 0 30px 0 8px;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  color: #142637 !important;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  background-color:#ffffff;
  background-color: transparent !important;
}

.select-input:focus {
  border:1px solid rgba(50, 151, 211, 0.25)
}


.details-page .detail-points{
  margin-bottom: 10px;
  font-size: 14px;
}

.nav-tabs.detail-tabs .nav-link {
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
  color: #142637;
}
.nav-tabs.detail-tabs .nav-link.active, .nav-tabs.detail-tabs .nav-item.show .nav-link, .nav-tabs.detail-tabs .nav-link.active, .nav-tabs.detail-tabs .nav-item:hover .nav-link {
  color: #ffffff;
  background-color: #142637;
  border-color: transparent;
}

.detail-tab-content {
  border: 1px solid #142637;
  border-radius: 5px 5px 5px 5px;
}
.nav-tabs.detail-tabs {
  border-bottom: none;
  /* margin-top: 25px; */
}

::placeholder{
  color: #adb5bd;
}

.detail-tab-content .card-body, .detail-tab-content p {
  font-size: 14px;
  color: #142637;
  font-weight: 400 !important;
}

.detail-tab-content .card-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.form-control-alternative {
 border: 1px solid #cad1d7 !important;
 box-shadow: none !important;
}

.chat-bubble {
  background-color: #f1f0f0;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
  max-width: 60%;
  word-wrap: break-word;
  border: 2px solid #142637;
}

.chat-bubble p {
  margin: 0;
  padding: 0;
}

/* .chat-bubble:before {
  content: "";
  position: absolute;
  top: 10px;
  left: -15px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 15px solid #f1f0f0;
  border-bottom: 10px solid transparent;
} */

.chat-bubble:before {
  content: "";
  position: absolute;
  top: 2px;
  left: -8px;
  width: 18px;
  height: 21px;
  border-top: 0px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 2px solid #142637;
  border-left: 2px solid #142637;
  transform: rotate(52deg);
  background-color: #f1f0f0;
}
.reply-bubble {
  background-color: #d1e7dd;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
  max-width: 60%;
  word-wrap: break-word;
  margin-left: auto;
  margin-right: 10px;
  border: 2px solid #142637;
}

.reply-bubble p {
  margin: 0;
  padding: 0;
}

.reply-bubble:before {
  content: "";
  position: absolute;
  top: 6px;
  right: -11px;
  width: 22px;
  height: 22px;
  border-top: 2px solid #142637;
  border-right: 2px solid #142637;
  /* border-bottom: 2px solid transparent; */
  transform: rotate(42deg);
  background-color: #d1e7dd;
}

/* .reply-bubble {
  position: relative;
  background: #d1e7dd;
  color: #FFFFFF;
  font-family: Arial;
  font-size: 20px;
  line-height: 120px;
  text-align: center;
  width: 250px;
  height: 120px;
  border-radius: 10px;
  padding: 0px;
}
.reply-bubble:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: transparent #d1e7dd;
  border-width: 20px 0 20px 20px;
  top: 50%;
  right: -20px;
  margin-top: -20px;
} */

.replies-section {
  margin-top: 20px;
}



.status-approved {
  color: green;
  font-weight: 700;
}

.status-rejected {
  color: red;
  font-weight: 700;
}

.status-pending {
  color: black; /* or any other default color */
}


.dropdown-btn-wrapper .dropdown-btn, .dropdown-btn-wrapper .dropdown-btn:hover, .dropdown-btn-wrapper .dropdown-btn:active {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 1px solid #cad1d7 !important;
  padding: 0 10px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
}
.dropdown-btn-wrapper .dropdown-btn::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0 !important;
  border-right: 2px solid #000 !important;
  border-bottom: 2px solid #000 !important;
  border-left: 0 !important;
  transform: rotate(45deg);
  width: 7px;
  height: 7px;
  transition: 0.1s all;
}

.form-control, .form-check-label{
  font-size: 14px !important;
}
.modal .modal-header {
  align-items: center;
  /* padding-bottom: 0px; */
  background-color: #19283e;
  padding: 10px 20px;
}
.modal .modal-header .modal-title{
    font-size: 16px;
    font-weight: 700;
    color: #ffffff !important;
}
.modal .modal-header .close{
  opacity: 1;
}

.modal .modal-header .close span{
  color: #ffffff;
}

.modal .modal-footer {
  padding-top: 0px !important;
}


.other-detail-section {
  margin: 20px 0;
  border-top: 2px solid;
  padding: 20px 0;
}
.details-page .other-detail-section .detail-points {
  padding-left: 15px;
}

body {
  color: #142637;
}
.footer {
  background: #ffffff !important;
  border-top: 1px solid #142637;
  padding: 1.5rem 15px!important;
  /* bottom: 0;
  right: 0;
  position: fixed;
  width: 100%;
  width: calc(100% - 250px); */
  z-index: 1;
  /* margin-left: auto; */
  /* margin-right: 0; */
}
.mt--7, .form-upper {
  margin-bottom: 2rem;
  min-height: calc(100vh - 270px)
}

.p-20{
  padding: 20px;
}


.detail-page-heading {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
  text-transform: capitalize;
}
.detail-img {
  width: 150px;
  height: 150px;
  margin: auto;
  border-radius: 50% !important;
}

.registeration-detail .detail-points {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}
.registeration-detail .detail-text {
  color: #685508;
  font-weight: 700;
  text-align: right;
}

.tabs-card {
  height: 100%;
}

.role-table th, .role-table td {
  padding: 1.5rem !important;
}
.role-table .table-checkbox .form-check-input {
  margin-left: 2px;
  cursor: pointer;
  width: 16px;
  height: 16px;
}


.role-table th, .role-table td {
  padding: 1.5rem !important;
}
.role-table .table-checkbox .form-check-input {
  margin-left: 2px;
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.dropdown-btn-wrapper .dropdown-btn, .dropdown-btn-wrapper .dropdown-btn:hover, .dropdown-btn-wrapper .dropdown-btn:active {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 1px solid #cad1d7 !important;
  padding: 0 10px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
}
.not-auth-pg{
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 25px;
  font-weight: 600;
}
/* .faq-table span{
  display: block;
} */

.faq-table span {
  display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
   text-overflow: ellipsis;
   text-overflow: ellipsis;
   height: 25px;
   overflow: hidden;
   white-space: normal;
}

.btn-grey{
  color: #ffffff !important;
  background-color: #6e6e6e !important;

}
.answer-shadow{
    box-shadow: 0 0 1rem 0 rgb(96 136 180 / 35%) !important;
}

.form-check-label {
  display: flex;
  align-items: baseline;
  cursor: pointer;
}

.sidebar-nav-item i {
  min-width: 30px !important;
}

.sidebar-nav-item .droptextcs{
  margin-left: 0px;
}
.profile-img .imgs {
  width: 100px;
  height: 100px;
  object-fit: contain;
}


@media only screen and (max-width:1399px){
  .card-title, .font_20 {
    font-size: 16px;
  }
  .mt--7 .mt-5, .form-upper .mt-5{
      margin-top: 2rem !important;
  }
 .nav-tabs.detail-tabs .nav-link, .detail-tab-content .card-title  {
    font-size: 14px;
}
.dropdown-btn-wrapper .dropdown-btn, .form-control, .select-input, .form-check-label {
  font-size: 13px !important;
}
.select-filter-wrapper {
  column-gap: 10px;
}
.detail-page-heading {
  font-size: 14px;
}
}


.star-rating .checked {
  color: orange;
}
.star-rating .star {
  color: #142637;
}